/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Button, Row, Col, Switch } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import Menu from './menu/';
import { Div, SmallScreenAuthInfo } from './style';
import UserInfo from './userInfo';
import UserDataHeader from './userDataHeader';
import { useSelector, useDispatch } from 'react-redux';

const { darkTheme } = require('../config/theme/themeVariables');
const LeftIcon = require('../static/img/icon/left.svg').default;
const RightIcon = require('../static/img/icon/right.svg').default;
const MainLogo = require('../static/img/bannerParaguay/logoParaguay.png').default;

const { Header, Footer, Sider, Content } = Layout;

const footerStyle = {
  padding: '20px 30px 18px',
  color: 'rgba(0, 0, 0, 0.65)',
  fontSize: '14px',
  background: 'rgba(255, 255, 255, .90)',
  width: '100%',
  boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
};

const SideBarStyle = {
  margin: '63px 0 0 0',
  padding: '15px 15px 55px 15px',
  overflowY: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
  zIndex: 998,
};

const AdminLayout = (WrappedComponent, path) => {

  const url = useLocation();
  const dispatch = useDispatch();
  const {menuStyle} = useSelector((state) => state.uiReducer);

  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      if(path === "/subastas/listado") {
        this.setState({
          collapsed: true,
        });
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    toggleCollapsed = () => {
      const { collapsed } = this.state;

      this.setState({
        collapsed: !collapsed,
      });
    }

    toggleCollapsedMobile = () => {
      const { collapsed } = this.state;

      if (window.innerWidth <= 990) {
        this.setState({
          collapsed: !collapsed,
        });
      }
    };

    onShowHide = () => {
      const { hide } = this.state;

      this.setState({
        hide: !hide,
        searchHide: true,
      });
    };

    handleSearchHide = e => {
      const { searchHide } = this.state;

      e.preventDefault();
      this.setState({
        searchHide: !searchHide,
        hide: true,
      });
    };

    renderView = ({ style, ...props }) => {
      const customStyle = {
        marginRight: '-17px',
      };
      return <div {...props} style={{ ...style, ...customStyle }} />;
    };

    renderThumbVertical = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        backgroundColor: '#F1F2F6',
        left: '2px',
      };
      return <div style={{ ...style, ...thumbStyle }} props={props} />;
    };

    renderTrackVertical = () => {
      const thumbStyle = {
        position: 'absolute',
        width: '6px',
        transition: 'opacity 200ms ease 0s',
        opacity: 0,
        right: '2px',
        bottom: '2px',
        top: '2px',
        borderRadius: '3px',
      };
      return <div style={thumbStyle} />;
    };

    renderThumbHorizontal = ({ style, ...props }) => {
      const thumbStyle = {
        borderRadius: 6,
        backgroundColor: '#F1F2F6',
      };
      return <div style={{ ...style, ...thumbStyle }} props={props} />;
    };

    onChange = async () => {
      await dispatch({type: "uiReducer/cambiarEstiloMenu"});
    }

    render() {
      const { collapsed, hide } = this.state;
      const darkMode = false;

      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                left: 0,
              }}
            >
              <Row align={"middle"}>

                <Col flex={1} className="align-center-v navbar-brand">
                  {menuStyle && <Button type="link" onClick={this.toggleCollapsed}>
                    <img src={collapsed ? RightIcon : LeftIcon} alt="menu" />
                  </Button>}

                  <Link
                    className={'striking-logo'}
                    to="/dashboard"
                  >
                    <img width={50} height={40} src={MainLogo} alt="" />
                  </Link>

                </Col>

                <UserDataHeader />

                <Col flex={1}>
                  <UserInfo />
                </Col>

                {/* <Col md={0} sm={2} xs={2}>
                  <div className="mobile-action">
                    <Link className="btn-auth" onClick={this.onShowHide} to="#">
                      <FeatherIcon icon="more-vertical" />
                    </Link>
                  </div>
                </Col> */}

              </Row>
            </Header>

            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                      <UserInfo />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>

            <Layout>
              {url && url.pathname !== "/mav-data" && <ThemeProvider theme={darkTheme}>
                {menuStyle ?
                <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={'light'}>
                <Scrollbars
                  className="custom-scrollbar"
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  renderThumbHorizontal={this.renderThumbHorizontal}
                  renderThumbVertical={this.renderThumbVertical}
                  renderView={this.renderView}
                  renderTrackVertical={this.renderTrackVertical}
                >
                  <Menu toggleCollapsed={this.toggleCollapsedMobile} />
                  
                </Scrollbars>
              </Sider>
              :
              <Header style={{
                position: 'fixed',
                width: '100%',
                bottom: 0,
                display: "flex",
                justifyContent: "space-around"
              }}>
                  <Menu toggleCollapsed={this.toggleCollapsedMobile} />
                </Header>}
              </ThemeProvider>}
              <Layout className={`${url && menuStyle && url.pathname !== "/mav-data" ? "atbd-main-layout" : "atbd-main-layout-mav-data"}`}>
                <Content>
                  <WrappedComponent {...this.props} />

                  {menuStyle && <Footer className="admin-footer" style={footerStyle}>
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">{(new Date().getFullYear())} © Mercado Argentino de Valores</span>
                      </Col>
                      <Col md={12} xs={24}>
                       
                      </Col>
                    </Row>
                  </Footer>}
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Div>
      );
    }
  }

  return LayoutComponent;
};
export default AdminLayout;
